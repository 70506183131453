import React, {lazy, Suspense} from "react"
import {Redirect, Route, Switch} from "react-router"
import PageLoader from "../../@jumbo/components/PageComponents/PageLoader"

const ExtraPages = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, "")

    return (
        <Suspense fallback={<PageLoader/>}>
            <Switch>
                <Redirect
                    exact
                    from={`${requestedUrl}/`}
                    to={`${requestedUrl}/editors`}
                />
                <Route
                    path={`${requestedUrl}/login`}
                    component={lazy(() => import("./sign-in/Default"))}
                />
                <Route
                    path={`${requestedUrl}/login-standard`}
                    component={lazy(() => import("./sign-in/Standard"))}
                />
                <Route
                    path={`${requestedUrl}/sign-up`}
                    component={lazy(() => import("./sign-up/Default"))}
                />
                <Route
                    path={`${requestedUrl}/sign-up-standard`}
                    component={lazy(() => import("./sign-up/Standard"))}
                />
                <Route
                    path={`${requestedUrl}/forgot-password`}
                    component={lazy(() => import("./forgot-password/Default"))}
                />
                <Route
                    path={`${requestedUrl}/forgot-password-standard`}
                    component={lazy(() => import("./forgot-password/Standard"))}
                />
                <Route
                    path={`${requestedUrl}/error-406`}
                    component={lazy(() => import("./406"))}
                />
                <Route
                    path={`${requestedUrl}/error-404`}
                    component={lazy(() => import("./404"))}
                />
                <Route
                    path={`${requestedUrl}/error-500`}
                    component={lazy(() => import("./500"))}
                />

                // Main Section Routes
                <Route
                    path={`${requestedUrl}/home`}
                    component={lazy(() => import("./Home"))}
                />
                <Route
                    path={`${requestedUrl}/trends`}
                    component={lazy(() => import("./Trends"))}
                />

                // Tools Section Route
                <Route
                    path={`${requestedUrl}/crm/:cid`}
                    component={lazy(() => import("./CRM"))}
                />
                <Route
                    path={`${requestedUrl}/smc`}
                    component={lazy(() => import("./SMC"))}
                />
                <Route
                    path={`${requestedUrl}/oct`}
                    component={lazy(() => import("./OCT"))}
                />
                <Route
                    path={`${requestedUrl}/mpa`}
                    component={lazy(() => import("./MPA"))}
                />
                <Route
                    path={`${requestedUrl}/cst`}
                    component={lazy(() => import("./CST"))}
                />

                // Data Section Routes
                <Route
                    path={`${requestedUrl}/users`}
                    component={lazy(() => import("./Profiling"))}
                />

                <Route
                    path={`${requestedUrl}/forms`}
                    component={lazy(() => import("./Forms"))}
                />

                <Route
                    path={`${requestedUrl}/prospects`}
                    component={lazy(() => import("./Prospects"))}
                />
                <Route
                    path={`${requestedUrl}/updates`}
                    component={lazy(() => import("./Updates"))}
                />

                // Account Section Routes
                <Route
                    path={`${requestedUrl}/manage-profile`}
                    component={lazy(() => import("./ManageProfile"))}
                />
                <Route
                    path={`${requestedUrl}/manage-users`}
                    component={lazy(() => import("./ManageUsers"))}
                />
                <Route
                    path={`${requestedUrl}/view-orders`}
                    component={lazy(() => import("./ViewOrders"))}
                />

                // Support Section Routes
                <Route
                    path={`${requestedUrl}/handbook`}
                    component={lazy(() => import("./Handbook"))}
                />
                <Route
                    path={`${requestedUrl}/translations`}
                    component={lazy(() => import("./Tool"))}
                />
                <Route
                    path={`${requestedUrl}/support-data`}
                    component={lazy(() => import("./FileTransfer"))}
                />
                <Route
                    path={`${requestedUrl}/support-finance`}
                    component={lazy(() => import("./FinanceSupport"))}
                />
                <Route
                    path={`${requestedUrl}/chat`}
                    component={lazy(() => import("../Apps/Chat"))}
                />
                <Route
                    path={`${requestedUrl}/contact`}
                    component={lazy(() => import("./Contact"))}
                />
                <Route
                    path={`${requestedUrl}/import`}
                    component={lazy(() => import('./Import'))}
                />
                <Route
                    path={`${requestedUrl}/export`}
                    component={lazy(() => import('./Export'))}
                />

                <Route component={lazy(() => import("./404"))}/>
            </Switch>
        </Suspense>
    )
}

export default ExtraPages
