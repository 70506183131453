export const SHOW_MESSAGE = "show_message"
export const HIDE_MESSAGE = "hide_message"
export const FETCH_START = "fetch_start"
export const FETCH_SUCCESS = "fetch_success"
export const FETCH_ERROR = "fetch_error"

export const UPDATE_AUTH_USER = "update_auth_user"
export const UPDATE_AUTH_USER_ONLY = "update_auth_user_only"
export const UPDATE_SETTINGS = "update_settings"
export const UPDATE_LOAD_USER = "update_load_user"
export const SEND_FORGET_PASSWORD_EMAIL = "send_forget_password_email"
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success"
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success"
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success"
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS"
export const SIGNIN_USER_SUCCESS = "signin_user_success"
export const SIGNOUT_USER_SUCCESS = "signout_user_success"
export const UPDATE_AUTH_USER_TOKEN = "update_auth_user_token"
export const UPDATE_AUTH_USER_ORDER_ID = "update_auth_user_order_id"
export const UPDATE_INITIAL_ORDER_CONFIG = "update_initial_order_config"
export const UPDATE_AUTH_WEBSITE = "update_auth_website"
export const UPDATE_USER_SETTINGS = "update_user_settings"
export const UPDATE_EXPORT_SETTINGS_FOR_CUSTOM = "update_export_settings_for_custom"

export const SET_DASHBOARD_DATA = "set_dashboard_data"

export const SET_TASK_CURRENT_USER = "set_task_current_user"
export const SET_TASKS_DATA = "set_tasks_data"
export const SET_TASK_LIST_DATA = "set_task_list_data"
export const ADD_TASK = "add_task"
export const DELETE_TASK = "delete_task"
export const UPDATE_TASK = "update_task"
export const SET_FILTER_DATA = "set_filter_data"
export const ADD_TASK_LIST = "add_task_list"
export const UPDATE_TASK_LIST = "update_task_list"
export const DELETE_TASK_LIST = "delete_task_list"
export const SET_TASK_DETAIL = "set_task_detail"
export const SEND_MESSAGE = "send_message"
export const TOGGLE_SIDEBAR_COLLAPSED = "toggle_sidebar_collapsed"
export const GET_TASKS_COUNTS = "get_tasks_counts"

//mail app
export const SET_LABELS_LIST = "get_labels_list";
export const GET_CONNECTIONS_LIST = "get_connections_list";
export const GET_MAILS_LIST = "get_mails_list";
export const UPDATE_MAIL_FOLDER = "update_mail_folder";
export const UPDATE_MAIL_LABEL = "upade_mail_label";
export const UPDATE_FAVORITE_STATUS = "update_favorite_status";
export const UPDATE_READ_STATUS = "update_read_status";
export const UPDATE_IMPORTANT_STATUS = "update_important_status";
export const COMPOSE_MAIL = "compose_mail";
export const SET_FILTER_TYPE = "set_filter_type";
export const GET_SELECTED_MAIL = "GET_SELECTED_MAIL";
export const UPDATE_SELECTED_MAIL = "update_selected_mail";
export const NULLIFY_SELECTED_MAIL = "nullify_selected_mail";
export const REPLY_TO_MAIL = "reply_to_mail";
export const GET_MAIL_COUNTS = "get_mail_count";
export const ADD_LABEL = "add_label";
export const ADD_CONNECTION = "add_connection";
export const REMOVE_CONNECTION = "remove_connection";

export const SET_CHAT_USERS = "set_chat_users";
export const SET_CONTACT_USERS = "set_contact_users";
export const SET_CURRENT_USER = "set_current_user";
export const SET_CONVERSATION_DATA = "set_conversation_data";
export const SEND_NEW_CHAT_MESSAGE = "send_new_chat_message";
export const SEND_NEW_MEDIA_MESSAGE = "send_new_media_message";

//Contact App
export const SET_CONTACTS_LIST = "get_contacts_list"
export const SET_CURRENT_CONTACT = "set_current_contact"
export const SET_CURRENT_SELECTION = "set_current_selection"
export const SET_CURRENT_PAGE_NUMBER = "set_current_page_number"
export const SET_ITEMS_PER_PAGE = "set_items_per_page"
export const SET_FOLDERS_LIST = "set_folders_list"
export const CREATE_CONTACT = "create_contact"
export const UPDATE_STARRED_STATUS = "update_starred_status"
export const DELETE_CONTACT = "delete_contact"
export const UPDATE_CONTACT_LABEL = "update_contact_label"
export const UPDATE_CONTACT = "update_contact"
export const GET_CONTACT_COUNTS = "get_contact_counts"
export const UPDATE_LABEL_ITEM = "update_label_item"
export const DELETE_LABEL_ITEM = "delete_label_item"
export const SET_PAGINATION_PAGE_COUNT = "set_pagination_page_count"
export const SET_ERROR_MSG = "set_error_msg"
export const SET_CURRENT_NOTE = "set_current_note"

export const GET_USER_DETAIL = "get_user_detail"
export const GET_FEED_POSTS = "get_feed_posts"
export const CREATE_POST = "create_post"
export const UPDATE_POST = "update_post"

// For Manipulating Footer From API
export const UPDATE_FOOTER = "update_footer"
export const UPDATE_FOOTER_TEXT = "update_footer_text"
export const UPDATE_FOOTER_VALUE = "update_footer_value"
export const UPDATE_FOOTER_LOGO_TEXT = "update_footer_logo_text"
export const UPDATE_FOOTER_BUTTON_MSG = "update_footer_button_msg"
export const UPDATE_FOOTER_BUTTON_URL = "update_footer_button_url"
export const UPDATE_FOOTER_BUTTON_TEXT = "update_footer_button_text"
export const UPDATE_FOOTER_TABLE_STATE = "update_footer_table_state"
export const UPDATE_FOOTER_ONLY_PROSPECT = "update_footer_only_prospect"
export const UPDATE_PROSPECT_CURRENT_STEP = "update_prospect_current_state"

// For Prospects Data
export const UPDATE_STEP1_DATA = "update_step1_data"
export const UPDATE_STEP2_DATA = "update_step2_data"
export const UPDATE_STEP3_DATA = "update_step3_data"
export const UPDATE_STEP4_DATA = "update_step4_data"
export const UPDATE_STEP5_DATA = "update_step5_data"
export const UPDATE_STEP6_DATA = "update_step6_data"
export const UPDATE_STEP7_DATA = "update_step7_data"
export const UPDATE_STEP8_DATA = "update_step8_data"
export const UPDATE_STEP9_DATA = "update_step9_data"
export const UPDATE_STEP10_DATA = "update_step10_data"
export const UPDATE_STEP11_DATA = "update_step11_data"
export const UPDATE_SNACKBAR_ALERT = "update_snackbar_alert"
export const SET_PROSPECTS_FOOTER = "set_prospects_footer"
export const UPDATE_STEP5_MARKERS = "update_step5_markers"
export const UPDATE_STEP7_FORM_DATA = "update_step7_form_data"
export const UPDATE_PAYMENT_BUTTON_ID = "update_payment_button_id"
export const SET_PROSPECTS_FOOTER_LEFT = "set_prospects_footer_left"
export const SET_PROSPECTS_FOOTER_RIGHT = "set_prospects_footer_right"
export const UPDATE_STEP5_ALL_LOCATIONS = "update_step5_all_locations"
export const UPDATE_STEP5_SELECTED_COUNTRY = "update_step5_selected_country"
export const SET_PROSPECTS_FOOTER_RIGHT_DETAILS = "set_prospects_footer_right_details"
export const MOVE_NEXT_TAB = "move_to_next_tab"
export const MOVE_PREVIOUS_TAB = "move_to_previous_tab"
export const UPDATE_CHECKED_LOCALSEO_ACCOUNT_IDS = "update_checked_localseo_account_ids"

// For Dynamic Navigation
export const UPDATE_SHOW_IFRAME = "update_show_iframe"
export const UPDATE_DYNAMIC_NAVIGATION = "update_dynamic_navigation"

// Enrich App
export const SET_ENRICH_DATA = "set_enrich_data"

// AI Chat App
export const SET_AI_CHAT_PROMPT = "set_ai_chat_prompt"
export const SET_AI_CHAT_REDIRECT = "set_ai_chat_redirect"
export const SET_AI_CHAT_CONTEXT_FILE = "set_ai_chat_context_file"
export const SET_AI_CHAT_LOCALIZATION = "set_ai_chat_localization"
export const SET_AI_CHAT_CONTEXT_DATASET = "set_ai_chat_context_dataset"
export const SET_AI_CHAT_PROMPT_RESPONSE = "set_ai_chat_prompt_response"
export const UPDATE_AI_CHAT_MODEL_FAVORITE = "update_ai_chat_model_favorite"
export const UPDATE_AI_CHAT_PROMPT_FAVORITE = "update_ai_chat_prompt_favorite"
