import {combineReducers} from "redux"
import {connectRouter} from "connected-react-router"

// Local Imports
import Auth from "./Auth"
import Chat from "./Chat"
import Footer from "./footer"
import Common from "./Common"
import Enrich from "./enrich"
import Prospect from "./prospects"
import Dashboard from "./Dashboard"
import ContactApp from "./contactApp"
import Navigation from "./Navigation"
import AppSettings from "./appSettings"

export default history =>
    combineReducers({
        router: connectRouter(history),
        common: Common,
        dashboard: Dashboard,
        chat: Chat,
        auth: Auth,
        contactApp: ContactApp,
        footer: Footer,
        prospect: Prospect,
        navigation: Navigation,
        enrichApp: Enrich,
        appSettings: AppSettings
    })
